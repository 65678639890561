import ScrollToTop from "@/src/components/app-items/loading/scroll-to-top.component";
import LoadingDots from "@components/icons/loading-dots";

const PageLoadingComponent = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <ScrollToTop />
      <LoadingDots />
    </div>
  );
};

export default PageLoadingComponent;
