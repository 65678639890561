"use client";
import { useTranslation } from "@/app/i18n/client";
import FormNumber from "@components/form-items/FormNumber";
import FormInput from "@components/form-items/FormInput";
import { PAYMENT_METHOD } from "@/src/lib/constants";
import { useOrderStore } from "@stores/rootStoreProvider";
import { Button, Checkbox, Drawer, Form } from "antd";
import { Suspense, useEffect, useState } from "react";
import { toast } from "sonner";
import PageLoadingComponent from "@/app/[domain]/loading";
import { addOrder } from "@/src/actions/finance/order";
import { RemoveButton } from "@/src/components/data-table/columns";
import { MinusCircle, PlusCircle, ShoppingCart } from "lucide-react";
import { validateMessages } from "@/src/lib/validation";
import { OptionModel } from "@/src/models/global";
import EmptyComponent from "../../app-items/empty.component";
import { formatCurrency } from "@/src/lib/helper";
import ConfettiExplosion from "react-confetti-explosion";
import FormTextArea from "../../form-items/FormTextArea";
import BlurImage from "../../blur-image";
import { cx } from "@/src/utils/all";
import { cn } from "@/lib/utils";

const rules = {
  siteId: [{ required: true }],
  userId: [{ required: true }],
  orderType: [{ required: true }],
  paymentMethod: [{ required: true }],
  contactName: [{ required: true }],
  contactPhone: [{ required: true }],
  contactEmail: [{ type: "email" as const }],
  deliveryAddress: [{ required: true }],
} as any;

export default function OrderFormPage({ user, site, products = [] }: any) {
  const [form] = Form.useForm();
  const { t } = useTranslation("en", "common");
  const orderStore = useOrderStore();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [createAccount, setCreateAccount] = useState(false);
  const [createOrderSuccess, setCreateOrderSuccess] = useState(false);
  const [productOptions] = useState<any>(
    (products ?? []).map((item: any) => new OptionModel(item.id, item.name)),
  );
  const [order, setOrder] = useState<any>({});

  useEffect(() => {
    setOrder(orderStore.editOrder);
    form.setFieldsValue(orderStore.editOrder);
    updateTotalAmount();
    hideShowDrawer();
  }, [orderStore.editOrder]);

  useEffect(() => {
    const init = async () => {
      const order = {
        ...orderStore.editOrder,
        siteId: site?.id,
        userId: user?.id,
        contactName: user?.username,
        contactEmail: user?.email,
        contactPhone: user?.phone,
        deliveryAddress: user?.address,
      };
      setOrder(order);
      form.setFieldsValue(order);
    };

    init();
  }, []);

  const hideShowDrawer = () => {
    const isOpen = !show;
    setShow(isOpen);
    if (!isOpen && createOrderSuccess) {
      setCreateOrderSuccess(false);
    }
  };

  const onSave = async () => {
    form.validateFields().then(async (values) => {
      setIsLoading(true);
      const newOrder = {
        ...values,
        paymentMethod: PAYMENT_METHOD.cash,
        siteId: site?.id,
        userId: user?.id,
      };
      newOrder.orderDetails.forEach((item: any) => {
        delete item.product;
      });

      const res = await addOrder(newOrder);
      if (res.error) {
        setIsLoading(false);
        toast.error(t(res.error));
        return;
      }

      setIsLoading(false);
      form.resetFields();
      setCreateOrderSuccess(true);
      orderStore.initOrder({ ...newOrder, orderDetails: [] });
    });
  };

  const handleSelectProduct = (productId: any, index: any) => {
    const product = products.find((item: any) => item.id === productId);
    const orderDetails = form.getFieldValue("orderDetails");
    if (orderDetails.length < index || product == null) {
      return;
    }
    // Update validation
    rules[`orderDetails.${index}.quantity`] = [
      {
        type: "number" as const,
        max: product.remaining,
        min: 1,
      },
    ];
    // Update product info
    orderDetails[index].unitPrice = product.price;
    form.setFieldsValue({ orderDetails });
    updateTotalAmount();
  };

  const updateTotalAmount = () => {
    // Calculate total amount by order details
    const orderDetails = form.getFieldValue("orderDetails") ?? [];
    const totalAmount = orderDetails.reduce(
      (acc: number, item: any) =>
        acc + Math.abs(item.unitPrice ?? 0) * Math.abs(item.quantity),
      0,
    );
    form.setFieldsValue({ totalAmount });
    setOrder({ ...order, orderDetails, totalAmount });
  };

  const updateQuantity = (index: any, value: any) => {
    const orderDetails = form.getFieldValue("orderDetails");
    if (orderDetails.length < index) {
      return;
    }
    const product = products.find(
      (item: any) => item.id === orderDetails[index].productId,
    );
    if (product == null) {
      return;
    }
    orderDetails[index].quantity += value;
    if (orderDetails[index].quantity < 0) {
      orderDetails[index].quantity = 0;
    } else if (orderDetails[index].quantity > product.remaining) {
      orderDetails[index].quantity = product.remaining;
    }
    form.setFieldsValue({ orderDetails });
    updateTotalAmount();
  };

  const orderCartComponent = () => {
    return (
      <div className="relative flex w-full min-w-0 flex-col break-words rounded-lg bg-white">
        <div>
          {/* PAYMENT */}
          <Form.List name="orderDetails">
            {(fields, { remove }) => (
              <>
                {/* PAYMENT DETAIL INFO */}
                <h2 className="font-semibold uppercase dark:text-white">
                  {t("order.detail")}
                </h2>
                <div className="border-blueGray-200 mt-5 flex flex-col border-t py-5">
                  {fields.map((field, index) => {
                    const selectedProductId = form.getFieldValue([
                      "orderDetails",
                      field.name,
                      "productId",
                    ]);
                    const selectedQuantity = form.getFieldValue([
                      "orderDetails",
                      field.name,
                      "quantity",
                    ]);
                    const selectedProduct = products.find(
                      (item: any) => item.id === selectedProductId,
                    );
                    const ruleQuantity =
                      rules[`orderDetails.${field.name}.quantity`];

                    return (
                      <div
                        className={cx(
                          "flex flex-row items-center gap-4 border-dashed py-4",
                          index < fields.length - 1 && "border-b",
                        )}
                        key={index}
                      >
                        <div className="flex flex-1 flex-wrap items-center gap-4">
                          <div className="flex-none">
                            <BlurImage
                              src={selectedProduct.image || "/empty-state.png"}
                              className="rounded-full"
                              style={{
                                objectFit: "cover",
                                width: "150px",
                                height: "150px",
                              }}
                              width={220}
                              height={220}
                              alt={selectedProduct.name}
                            />
                          </div>
                          <div className="flex flex-1 flex-col gap-4 md:flex-row md:items-center">
                            <div className="flex flex-1 flex-col flex-wrap gap-2">
                              <div className="text-base font-semibold">
                                {selectedProduct.name}
                              </div>
                              <div className="text-gray-500">
                                <div>
                                  {t("order.unitPrice")}:{" "}
                                  <span className="text-gray-700">
                                    {formatCurrency(selectedProduct.price)} -{" "}
                                    {selectedProduct.unit}
                                  </span>
                                </div>
                                <div>
                                  {t("order.remainingItems")}{" "}
                                  <span className="text-gray-700">
                                    {selectedProduct.remaining ?? "--"}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-none items-center gap-2">
                              <MinusCircle
                                onClick={() => updateQuantity(index, -1)}
                                className={
                                  selectedQuantity <= 0
                                    ? "text-gray-300"
                                    : "cursor-pointer"
                                }
                              />
                              <FormNumber
                                name={[field.name, "quantity"]}
                                rule={ruleQuantity}
                                formClass="mb-0 w-[70px]"
                              />
                              <PlusCircle
                                onClick={() => updateQuantity(index, 1)}
                                className={
                                  selectedQuantity >= selectedProduct.remaining
                                    ? "text-gray-300"
                                    : "cursor-pointer"
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="mt-1 flex flex-none justify-end">
                          <RemoveButton
                            onClick={() => remove(field.name)}
                            className="ml-2"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </Form.List>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="sticky right-8 top-8">
        <div>
          <span
            className="shopping-cart flex h-10 w-10 cursor-pointer items-center justify-center rounded-full border border-slate-200 bg-white p-1 hover:border-slate-400"
            onClick={hideShowDrawer}
          >
            <ShoppingCart size={24} />
            {order.orderDetails?.length > 0 && <div className="dot"></div>}
          </span>
        </div>
      </div>

      <Drawer
        onClose={hideShowDrawer}
        open={show}
        closable={false}
        placement="bottom"
        width="50%"
        height="80%"
        title={
          <h2 className="text-xl font-semibold dark:text-white">
            {t("order.orderTitle")}
          </h2>
        }
        extra={
          <div>
            <Button
              disabled={isLoading || order.orderDetails?.length <= 0}
              loading={isLoading}
              onClick={onSave}
              type="primary"
            >
              {t("btn.createOrder")}
            </Button>
          </div>
        }
        footer={
          <div className="flex justify-between">
            {/* SUMMARY */}
            <h2 className="font-semibold uppercase dark:text-white">
              {t("order.summaryGroup")}
            </h2>

            <div className="text-lg font-semibold">
              {formatCurrency(order.totalAmount)}
            </div>
          </div>
        }
      >
        <Form
          form={form}
          onFinish={onSave}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={updateTotalAmount}
          validateMessages={validateMessages}
          className="custom-form"
        >
          <Suspense fallback={<PageLoadingComponent />}>
            {order.orderDetails?.length <= 0 && !createOrderSuccess && (
              <EmptyComponent
                imgUrl="/images/empty/empty-cart.png"
                imgWidth={200}
                imgHeight={200}
                title={t("order.emptyCartTitle")}
                description={t("order.emptyCartDescription")}
              />
            )}
            {createOrderSuccess && (
              <div className="relative">
                <ConfettiExplosion zIndex={2000} />
                <EmptyComponent
                  imgUrl="/images/empty/success.png"
                  imgWidth={120}
                  imgHeight={120}
                  title={t("order.createOrderSuccessTitle")}
                  description={t("order.createOrderSuccessDescription")}
                />
              </div>
            )}
            {order.orderDetails?.length > 0 && (
              <>
                <div className="flex flex-col gap-4">
                  {orderCartComponent()}
                  {/* Contact info */}
                  <div className="relative flex w-full min-w-0 flex-col break-words">
                    <h2 className="font-semibold uppercase dark:text-white">
                      {t("order.contactInfo")}
                    </h2>
                    <div className="border-blueGray-200 mt-5 border-t py-5">
                      <div className="flex flex-wrap gap-4">
                        <div className="w-full md:w-1/3">
                          <FormInput
                            label={t("user.name")}
                            name="contactName"
                            rule={rules.contactName}
                            formClass="mb-0"
                          ></FormInput>
                        </div>
                        <div className="flex-1 md:w-1/3">
                          <FormInput
                            label={t("user.email")}
                            name="contactEmail"
                            rule={rules.contactEmail}
                            formClass="mb-0"
                          ></FormInput>
                        </div>
                        <div className="flex-1 md:w-1/3">
                          <FormInput
                            label={t("user.phone")}
                            name="contactPhone"
                            rule={rules.contactPhone}
                            formClass="mb-0"
                          ></FormInput>
                        </div>
                        <div className="w-full">
                          <FormTextArea
                            label={t("order.address")}
                            name="deliveryAddress"
                            rule={rules.deliveryAddress}
                            formClass="mb-0"
                          ></FormTextArea>
                        </div>
                        {/* This function not ready yet */}
                        {false && (
                          <>
                            <div className="mb-2 w-full">
                              <Checkbox
                                checked={createAccount}
                                onChange={() =>
                                  setCreateAccount(!createAccount)
                                }
                              >
                                {t("order.doYouWantToCreateAccount")}
                              </Checkbox>
                            </div>
                            {createAccount && (
                              <>
                                <div className="md:w-1/3">
                                  <FormInput
                                    label={t("order.password")}
                                    name="password"
                                    formClass="mb-0"
                                  ></FormInput>
                                </div>
                                <div className="md:w-1/3">
                                  <FormInput
                                    label={t("order.rePassword")}
                                    name="rePassword"
                                    formClass="mb-0"
                                  ></FormInput>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Payment method */}
                  {user?.id && (
                    <div className="relative flex w-full min-w-0 flex-col break-words">
                      <h2 className="font-semibold uppercase dark:text-white">
                        {t("order.payment")}
                      </h2>
                    </div>
                  )}
                </div>
              </>
            )}
          </Suspense>
        </Form>
      </Drawer>
    </>
  );
}
