import { formatDateTime } from "@/src/lib/helper";
import { EditOutlined } from "@ant-design/icons";
import { CalendarDays, EditIcon, Pencil, TrashIcon } from "lucide-react";
import { AppConsts } from "@lib/constants";
const { align } = AppConsts;

const SystemColumn = {};

interface IProps {
  onClick: () => void;
  className?: String;
}

export const EditButton = ({ onClick }: IProps) => {
  return (
    <div
      className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-blue-600 text-center text-sm font-bold text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      onClick={onClick}
    >
      <Pencil strokeWidth={1.25} size={16} />
    </div>
  );
};

export const RemoveButton = ({ onClick }: IProps) => {
  return (
    <div
      className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full bg-blue-600 text-center text-sm text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
      onClick={onClick}
    >
      <TrashIcon strokeWidth={1.25} size={16} />
    </div>
  );
};

export const ColumnUpdatedAt = (t: any) => {
  return {
    title: t("updatedAt"),
    dataIndex: "updatedAt",
    key: "updatedAt",
    width: 150,
    ellipsis: true,
    render: (updatedAt: string) => {
      return (
        <span className="markdown flex gap-1 text-gray-400">
          <CalendarDays /> {formatDateTime(updatedAt)}
        </span>
      );
    },
  };
};

export default SystemColumn;
