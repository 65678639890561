"use client";
import { cx } from "src/utils/all";
import { formatNumber as formatNumberKMB } from "react-tweet";
import BlurImage from "../../blur-image";
import { useOrderStore } from "@/src/stores/rootStoreProvider";
import { useTranslation } from "@/app/i18n/client";
import { Button } from "antd";
import { ShoppingCart } from "lucide-react";

export default function MenuItemComponent({ data, onClick, addToCard }: any) {
  const { t } = useTranslation("en", "common");
  const { name, image, price, unit, aspect, remaining } = data;
  const orderStore = useOrderStore();

  const handleAddToCard = () => {
    orderStore.addProduct(data, 1);
    if (addToCard) {
      addToCard();
    }
    orderStore.showDrawer = true;
  };

  return (
    <div
      className={cx("link-effect", remaining > 0 ? "cursor-pointer" : "")}
      onClick={
        remaining > 0 && addToCard ? handleAddToCard : onClick ?? undefined
      }
    >
      <div className="flex justify-center">
        <div
          className={cx(
            "relative h-[220px] w-[220px] rounded-full bg-lime-600 transition-all hover:scale-105",
            aspect === "landscape" ? "aspect-video" : "aspect-square",
          )}
        >
          <BlurImage
            src={image || "/empty-state.png"}
            alt={name}
            className="rounded-full"
            style={{ objectFit: "cover", width: "220px", height: "220px" }}
            width={220}
            height={220}
          />
          {/* Show add to cart when hover */}
          {remaining > 0 && addToCard && (
            <div className="absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 transition-opacity hover:opacity-100">
              <Button
                icon={<ShoppingCart size={16} />}
                shape="circle"
                className="border-none"
              ></Button>
            </div>
          )}
        </div>
      </div>
      <h2 className="text-brand-primary mt-2 flex justify-center text-center text-lg font-semibold tracking-normal dark:text-white">
        <span className="link-underline link-underline-blue uppercase">
          {name}
        </span>
      </h2>

      <div className="flex justify-center text-base">
        {formatNumberKMB(price)} - {unit}
      </div>
      <div className="flex justify-center text-base">
        <span className="text-gray-500">
          {t("order.remainingItems")}{" "}
          <span className="text-gray-700">{remaining ?? "--"}</span>
        </span>
      </div>
    </div>
  );
}
