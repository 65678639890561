"use client";
import PagedItem from "@components/app-items/paged-item/paged-item";
import { useEffect, useState } from "react";
import { useSearchParams } from "next/navigation";
import LoadingItemComponent from "../../app-items/loading/loading-item.component";
import PostCardComponent from "@components/post/post-card.component";
import { filterPost } from "@/src/actions/post";
import { POST_STATUS } from "@/src/lib/constants";

interface IProps {
  initData?: any;
  siteId?: string;
}

const ListPostByCategory = ({ initData, siteId }: IProps) => {
  const searchParams = useSearchParams();
  const [pagedData, setPagedData] = useState<any>(
    initData ?? {
      list: [],
      total: 0,
    },
  );
  const [firstInit, setFirstInit] = useState(true);
  const [posts, setPosts] = useState<any>(initData?.results || []);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<any>({
    page: 1,
    limit: 8,
    siteId,
    status: POST_STATUS.published,
    isFeatured: "false",
  });

  useEffect(() => {
    const categoryParam = (
      searchParams.get("category") === "all" ? "" : searchParams.get("category")
    )?.toLowerCase();
    const queryParam = searchParams.get("q");
    if (categoryParam != filter.category || queryParam != filter.q) {
      setPosts([]);
      setFilter({
        ...filter,
        page: 1,
        category: categoryParam,
        q: queryParam,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    if (firstInit) {
      setFirstInit(false);
      return;
    }
    fetchPosts();
  }, [filter]);

  const fetchPosts = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    let pagingData: any = await filterPost(filter);
    setPagedData(pagingData);
    setPosts([...posts, ...pagingData.results]);
    setIsLoading(false);
  };

  return (
    <div className="mt-10 grid gap-10 md:grid-cols-2 lg:gap-10 xl:grid-cols-3">
      {(posts || []).map((post: any, i: number) => {
        return (
          <PagedItem
            key={i}
            data={post}
            loadNextPage={() => {
              setFilter({ ...filter, page: filter.page + 1 });
            }}
            isLast={i === posts.length - 1}
            canLoad={posts.length < pagedData.totalCount}
          >
            <PostCardComponent
              title={post.title}
              coverImage={post.image}
              date={post.createdAt}
              author={post.user}
              slug={post.slug}
              excerpt={post.excerpt}
              link={post.link}
              priority={false}
            />
          </PagedItem>
        );
      })}
      {isLoading && <LoadingItemComponent />}
    </div>
  );
};

export default ListPostByCategory;
