"use client";
import Image from "next/image";

interface IProps {
  title: string;
  description: string;
  imgUrl?: string;
  imgWidth?: number;
  imgHeight?: number;
}

const EmptyComponent = ({
  title,
  description,
  imgUrl,
  imgWidth,
  imgHeight,
}: IProps) => {
  return (
    <div className="mt-20 flex flex-col items-center space-x-4">
      <Image
        alt="missing site"
        src={imgUrl ?? "https://illustrations.popsy.co/gray/web-design.svg"}
        width={imgWidth ?? 400}
        height={imgHeight ?? 400}
      />
      <div className="font-cal py-4 text-4xl">{title}</div>
      <p className="text-center text-lg text-stone-500">{description}</p>
    </div>
  );
};

export default EmptyComponent;
