"use client";

import { useEffect, useState } from "react";
import MenuItemComponent from "../menu-item/menu-item.component";
import OrderDrawer from "../order/order-drawer";

export default function ProductListComponent({ data, user, site }: any) {
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [groupByCategory, setGroupByCategory] = useState<any>([]);

  useEffect(() => {
    // Group by category
    let groups = data.reduce((acc: any, item: any) => {
      if (!acc[item.category.id]) {
        acc[item.category.id] = { category: item.category, items: [] };
      }
      acc[item.category.id].items.push(item);
      return acc;
    }, {});
    // Convert to array
    groups = Object.keys(groups).map((key: string) => {
      return groups[key];
    });
    setGroupByCategory(groups);
  }, [data]);

  const handleClick = () => {
    setTriggerUpdate(triggerUpdate + 1);
  };

  return (
    <>
      {data.length > 0 && (
        <OrderDrawer user={user} site={site} products={data} />
      )}
      {(groupByCategory || []).map((item: any) => (
        <div key={item.category.id}>
          {/* Horizontal divider with category title */}
          {groupByCategory.length > 1 && (
            <div className="col-span-full my-12">
              <div className="flex items-center justify-center gap-2">
                <div className="h-[1px] flex-1 bg-slate-200"></div>
                <h2 className="flex-none text-2xl font-semibold uppercase text-gray-800 dark:text-gray-200">
                  {item.category.name}
                </h2>
                <div className="h-[1px] flex-1 bg-slate-200"></div>
              </div>
            </div>
          )}
          {/* List of products */}
          <div className="grid gap-10 md:grid-cols-2 lg:gap-20 lg:px-12 xl:grid-cols-3">
            {item.items.map((item: any) => (
              <MenuItemComponent
                key={item.id}
                data={item}
                addToCard={handleClick}
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
}
