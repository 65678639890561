"use client";
import { filterCategory } from "@/src/actions/category";
import { CategoryModel } from "@/src/models/cms/category.model";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";

interface IProps {
  siteId?: string;
}

const NavCategory = ({ siteId }: IProps) => {
  const searchParams = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState<any>([]);
  const [current, setCurrent] = useState(searchParams.get("category") ?? "all");

  useEffect(() => {
    const init = async () => {
      const pagedData = await filterCategory({
        page: 1,
        limit: 6,
        siteId: siteId,
      });
      const categories = pagedData.results || [];
      setCategories(categories);
      setLoading(false);
    };
    init();
  }, []);

  useEffect(() => {
    const paramCategory = (searchParams.get("category") ?? "all").toLowerCase();
    if (current != paramCategory) {
      setCurrent(paramCategory ?? "");
    }
  }, [searchParams]);

  const activeClass = "text-blue-600 font-medium";

  const loadingComponent = loading
    ? [1, 2, 3].map((item) => (
        <li
          key={item}
          className="flex animate-pulse items-center justify-center px-4 py-2 leading-6"
        >
          <div className="h-2 w-[75px] rounded bg-slate-200"></div>
        </li>
      ))
    : "";

  return (
    <div className="container">
      <ul className="flex border-b">
        <li
          className={`cursor-pointer hover:text-blue-600 ${
            current === "all" ? activeClass : "text-slate-500"
          }`}
        >
          <Link href="search?category=all">
            <div className="px-4 py-2 text-lg">All</div>
          </Link>
        </li>
        {loadingComponent}
        {categories.map((item: CategoryModel) => {
          const active = current === item.slug ? activeClass : "text-slate-500";
          return (
            <li
              className={`cursor-pointer hover:text-blue-600 ${active}`}
              key={item.id}
            >
              <Link href={`search?category=${item.slug}`}>
                <h2 className="px-4 py-2 text-lg">{item.name}</h2>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NavCategory;
