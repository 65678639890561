import React, { useEffect, useState } from "react";
import { InputNumber } from "antd";
import usePrevious from "src/utils/hooks/usePrevious";
import isEqual from "lodash/isEqual";
import { inputNumberFormatter, inputNumberParse } from "src/utils/helper";
import debounce from "lodash/debounce";

interface NumberInputProps {
  value?: number;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  suffix?: React.ReactNode;
  max?: number;
  min?: number;
  locale?: string;
  disabled?: boolean;
  placeholder?: string;
  size?: "small" | "middle" | "large" | undefined;
  isPercent?: boolean;
  precision?: number;
  className?: string;
}

const NumberInput: React.FC<NumberInputProps> = ({
  value,
  onChange,
  onBlur,
  suffix,
  locale = "vi",
  disabled,
  min,
  max,
  placeholder,
  size = "large",
  isPercent,
  precision,
  className = "w-full",
}) => {
  const previousValue = usePrevious(value);
  const [currencyValue, setNumberValue] = useState(value);

  useEffect(() => {
    if (!isEqual(previousValue, value)) {
      setNumberValue(value);
    }
  }, [value]);

  const triggerChange = () => {
    if (onChange) {
      onChange(currencyValue);
    }
  };

  const onTextChange = debounce((e) => {
    setNumberValue(e);
  }, 100);

  const handleBlur = () => {
    triggerChange();
    if (onBlur) {
      onBlur(currencyValue);
    }
  };

  return (
    <InputNumber
      // type="number"
      className={className}
      value={currencyValue}
      onChange={onTextChange}
      formatter={
        isPercent
          ? (value) => `${value}%`
          : (value) => inputNumberFormatter(value)
      }
      parser={
        isPercent
          ? (value) => Number(value?.replace("%", ""))
          : (value) => inputNumberParse(value)
      }
      onBlur={handleBlur}
      disabled={disabled}
      min={min}
      max={max}
      placeholder={placeholder || ""}
      size={size || "large"}
      addonAfter={suffix}
      precision={precision}
    />
  );
};

export default NumberInput;
