import { emailRegex, themeByEvent } from "src/lib/constants";

export function inputCurrencyFormatter(
  value: number | undefined,
  locale = "vi",
  symbol = "đ",
) {
  return `${symbol} ${(value + "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

export function inputCurrencyParse(
  value: string | undefined,
  locale = "vi",
  symbol = "đ",
) {
  if (!value) return 0;
  return Number(
    value
      .replace(symbol, "")
      .replace(" ", "")
      .replace(/\$\s?|(,*)/g, ""),
  );
}

export function inputNumberFormatter(value: any) {
  return value.toString().replace(/^[+-]?\d+/, function (int: any) {
    return int.replace(/(\d)(?=(\d{3})+$)/g, "$1,");
  });
}

export function inputNumberParse(value: any) {
  return value.replace(/\$\s?|(,*)/g, "");
}

export function isValidEmail(text: string) {
  if (!text || isNullOrEmpty(text)) {
    return false;
  }
  return emailRegex.test(text);
}

export function isNullOrEmpty(text: string) {
  if (!text) {
    return true;
  }

  text = text.trim();
  return text.length < 1;
}

export const extractHeaderFromMarkdown = (markdown: string) => {
  const HEADING_R = /(#{1,6} .*)\r?\n/g;
  const headings = Array.from(markdown.matchAll(HEADING_R), (m) => m[1]);

  return (headings.length ? headings[0] : "").replace("#", "").trim();
};

export function getCountDownXmasMessage(loaderMessage: string) {
  // Find the distance between now and the count down date
  // Get today's date and time
  const countDownDate = new Date(new Date().getFullYear(), 11, 25).getTime();
  const now = new Date().getTime();
  const distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  return days === 0
    ? "Merry Christmas!"
    : (loaderMessage || "").replace("{0}", `${days}`);
}

export function changeBackgroundByEvent(event?: string, type?: string) {
  //Start the snow default options you can also make it snow in certain elements, etc.
  const { events } = themeByEvent;
  switch (event) {
    case events.xmasNight:
    case events.xmasHouse:
    case events.xmasSanta: {
      const fjs = document.getElementsByTagName("script")[0];
      if (document.getElementById("blog-xtraffic-snow-effect")) return;
      const js = document.createElement("script");
      js.id = "blog-xtraffic-snow-effect";
      js.src = "/assets/script/snow-storm.js";
      fjs.parentNode && fjs.parentNode.insertBefore(js, fjs);
      break;
    }
  }
}
