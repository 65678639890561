import React from "react";
import { Form, FormItemProps, Input } from "antd";

import { AppConsts } from "src/lib/constants";

const { formVerticalLayout } = AppConsts;
interface FormTextAreaProps extends FormItemProps {
  rule?: any;
  rows?: number;
  className?: string;
  formClass?: string;
  showCount?: boolean;
  disabled?: boolean;
}

const FormTextArea: React.FC<FormTextAreaProps> = ({
  label,
  name,
  rule,
  rows,
  extra,
  showCount,
  formClass,
  disabled,
  className = "w-full rounded-md border border-stone-200 px-4 py-2 text-sm text-stone-600 placeholder:text-stone-400 focus:ring-black dark:border-stone-600 dark:bg-black dark:text-white dark:placeholder-stone-700 dark:focus:ring-white bg-white",
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rule}
      extra={extra}
      className={"w-full" + (formClass ? " " + formClass : "")}
      {...formVerticalLayout}
    >
      <Input.TextArea
        rows={rows || 3}
        className={className}
        showCount={showCount}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default FormTextArea;
