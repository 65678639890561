import React from "react";
import { Form } from "antd";
import { AppConsts } from "src/lib/constants";
import NumberInput from "src/components/inputs/NumberInput";
import "./input-number.module.scss";

const { formVerticalLayout } = AppConsts;
interface FormNumberProps {
  label?: string;
  name: string | (string | number)[];
  extra?: string;
  rule?: any;
  suffix?: any;
  min?: number;
  max?: number;
  precision?: number;
  disabled?: boolean;
  formClass?: string;
  className?: string;
  placeholder?: string;
  onChange?: (value: any) => void;
  size?: "small" | "middle" | "large" | undefined;
}

const FormNumber: React.FC<FormNumberProps> = ({
  label,
  name,
  extra,
  rule,
  suffix,
  min,
  max,
  precision,
  disabled,
  formClass,
  placeholder,
  className = "w-full rounded-md text-sm text-stone-600 placeholder:text-stone-400 dark:border-stone-600 dark:bg-black dark:text-white dark:placeholder-stone-700 ",
  onChange,
  size,
}) => {
  return (
    <Form.Item
      label={label}
      name={name}
      rules={rule}
      extra={extra}
      {...formVerticalLayout}
      className={formClass}
    >
      <NumberInput
        suffix={suffix}
        min={min}
        max={max}
        precision={precision}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        onChange={onChange}
        size={size}
      />
    </Form.Item>
  );
};

export default FormNumber;
